<template>
    <div>
        <div style="padding: 0 50px;font-size: 1.17em;user-select:none;-moz-user-select:none;-webkit-user-select:none;">
            <div class="text-right" style="padding-right: 200px;">合同编号：{{ cmData.contractorNum }}</div>
            <h1 class="text-center">保障性租赁住房租赁合同（合租版）</h1>
            <div class="text-indent padding_5_0">出租方：
                <span class="span_decoration">南宁城市建设投资集团有限责任公司</span>
            </div>
            <div class="text-indent padding_5_0">
                承租方： <span class="span_decoration">{{ cmData.lesseeName }}</span>
                身份证号： <span class="span_decoration">{{ cmData.lesseeIdCard }}</span>
                联系地址： <span class="span_decoration">{{ cmData.Address }}</span>
            </div>
            <div class="text-indent padding_5_0">
                手机号码： <span class="span_decoration">{{ cmData.phone }}</span>
                紧急联系人：<span class="span_decoration">{{ cmData.contractName }}</span>
                紧急联系电话： <span class="span_decoration">{{ cmData.contractPhone }}</span>
            </div>
            <div style="height: 20px;"></div>
            <div class="text-indent">
              双方当事人在自愿、平等、公平及诚实信用的基础上，根据《中华人民共和国民法典》《中华人民共和国城市房地产管理法》等法律法规的规定，就出租方向承租方出租其房屋相关内容协商一致，签订本房屋租赁合同。
            </div>
            <div style="height: 20px;"></div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第一条 房屋基本状况</h3>
            <div class="text-indent padding_5_0">
                出租方所出租的房屋（以下简称“住房”）位于
                <span class="span_decoration">{{ cmData.locationAddress }}</span>；
                房屋户型为 <span class="span_decoration">{{ cmData.fangNum }}</span> 房
                <span class="span_decoration">{{ cmData.officeNum }}</span> 厅
                <span class="span_decoration">{{ cmData.roomNum }}</span> 室 ；
                建筑面积 <span class="span_decoration">{{ cmData.outArea }}</span> ㎡，
                计租面积 <span class="span_decoration">{{ cmData.useArea }}</span>㎡；
              房屋性质为保障性租赁住房，房屋用途为住宅；该房屋不带车库/车位/储物间出租。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第二条 租赁期限的约定</h3>
            <div class="text-indent padding_5_0">
                该房屋租赁期自 <span class="span_decoration">{{ cmData.contractStartTime }}</span>
                至 <span class="span_decoration">{{ cmData.contractEndTime }}</span> ，共计
                <span class="span_decoration">{{ cmData.months }}</span> 个月。
            </div>
            <div class="text-indent padding_5_0">
              租赁期届满，承租方如要求续租，应在租赁期满前一个月在出租方的电子平台进行续签业务申请，经审核符合条件的准予续租，并签订续租合同；在同等条件下原承租方享有优先承租权，原承租方存在不宜续租的违约行为的除外。
            </div>
            <div class="text-indent padding_5_0">
              未按规定提出续租申请的承租方，租赁期满前一个月承租方需通知出租方不再继续承租房屋，并无条件配合出租方出租看房工作，在租赁期届满须恢复房屋如入住时的样子后自行腾退保障性租赁住房；拒不腾退的，出租方有权责令限期腾退并收回房屋。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第三条 租金、服务费、电费的约定</h3>
            <div class="text-indent padding_5_0">
                该房屋租金单价为： <span class="span_decoration">{{ cmData.unitPrice }}</span>
                元/㎡/月；该房屋租金合计（人民币）：
                <span class="span_decoration">{{ cmData.rentalAmount }}</span> 元/月（大写：
                <span class="span_decoration">{{ cmData.rentalAmountUppercase }}</span>），房屋租金金额含增值税，增值税税率为<span
                class="span_decoration">{{ cmData.taxRate }}</span>%，税率随国家税收政策调整而变化。租金不含税金额为<span
                class="span_decoration">{{ cmData.taxRateJ }}</span>元/月；本合同租金不随相似房源租金变动而变动。
            </div>
            <div class="text-indent padding_5_0">
              服务费为：  <span class="span_decoration">{{cmData.tenementAmount }}</span>     元/月（大写： <span class="span_decoration">{{cmData.tenementAmountUppercase }}</span>        ），服务费金额含增值税，增值税税率为  <span class="span_decoration">6</span>     %，税率随国家税收政策调整而变化。服务费不含税金额为  <span class="span_decoration">{{cmData.noTaxTenementAmount }}</span>     元/月；
            </div>
<!--          <div class="text-indent padding_5_0">-->
<!--            室内使用电费、公区电费为：承租方入住时，根据整套房屋年度累计使用电量，实行分档递增计算合租房实时电价。室内使用电费、公区电费按照《广西壮族自治区物价局关于调整居民生活用电阶梯电价政策有关问题的通知》（桂价格〔2016〕63号）“居民用户的电费采取阶梯电价进行收费，每年用电量分为三档，-->
<!--            电价实行分档递增。阶梯电价标准为第一档用电量标准2760(千瓦时)及以下，电价0.5283元/千瓦时。第二档用电量标准2761(千瓦时)-4440(千瓦时)（含），电价0.5783元/千瓦时。第三档用电量标准年度累计＞4440(千瓦时)，电价0.8283元/千瓦时”。具体室内使用电费、-->
<!--            公区电费及阶梯电价以出租方的电子平台数据为准。室内使用电费、公区电费金额含增值税，增值税税率为  <span class="span_decoration">13</span>  %，税率及电价随国家税收政策、现行电价政策调整而变化。-->
<!--          </div>-->
          <div class="text-indent padding_5_0">
            室内使用电费、公区电费为：承租方入住时，根据整套房屋年度累计使用电量，实行分档递增计算合租房实时电价。室内使用电费、公区电费按照《广西壮族自治区物价局关于调整居民生活用电阶梯电价政策有关问题的通知》（桂价格〔2016〕63号）“居民用户的电费采取阶梯电价进行收费，每年用电量分为三档，电价实行分档递增。阶梯电价标准为第一档用电量标准2760(千瓦时)及以下，电价0.5283元/千瓦时。第二档用电量标准2761(千瓦时)-4440(千瓦时)（含），
            电价0.5783元/千瓦时。第三档用电量标准年度累计＞4440(千瓦时)，电价0.8283元/千瓦时”。具体室内使用电费、公区电费及阶梯电价以出租方的电子平台数据为准。室内使用电费、公区电费金额含增值税，增值税税率为
             <span class="span_decoration">13</span>  %，税率及电价随国家税收政策、现行电价政策调整而变化。
          </div>
          <div class="text-indent padding_5_0">
            入住当月不足一个月的按天计收入住当月租金、服务费及每月25日前预缴次月租金、服务费，室内电费、公摊电费为预缴模式，先充值后使用，承租方可通过银行汇款（转账支付时需备注小区、房号、姓名等信息）或出租方的电子平台缴纳租金、服务费、室内电费、公摊电费。
          </div>
          <div class="text-indent padding_5_0">
            如发生拖欠租金、服务费、室内电费、公摊电费行为的，出租方有权按第十三条第（一）项约定执行。合同提前解除的，未满一个月的租金、服务费按天计费，出租方应在承租方办结退房手续后一个月内退还已收但尚未发生的租金、服务费、室内电费、公摊电费，承租方应当在合同解除后当天内搬离。
          </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第四条 租赁保证金的约定</h3>
            <div class="text-indent padding_5_0">
              （一）承租方应于签订本合同时一次性交纳房屋租赁保证金。租赁保证金的金额相当于本合同租赁期一个自然月租金，租赁保证金合计（人民币）
                <span class="span_decoration">{{ cmData.depoAmount }}</span> 元（大写：
                <span class="span_decoration">{{ cmData.depoAmountUppercase }}</span>）。
            </div>
            <div class="text-indent padding_5_0">
              （二）租赁期间，承租方因违约造成出租方损失的，须支付相应费用的，出租方有权从其交纳的租赁保证金中扣抵，出租方依前述约定扣抵承租方租赁保证金的，承租方应在扣抵事实发生之日起五个工作日内向出租方补齐租赁保证金。逾期不补租赁保证金的按拖欠租金约定承担违约责任。
            </div>
            <div class="text-indent padding_5_0">
              （三）合同未生效、租赁期满或其他原因导致本合同终止时，承租方无违约行为，且承租方结清该房屋所欠租金、服务费、室内电费、公摊电费、电、网络、通讯、有线电视等应由承租方负责交纳的各项费用（以有效结清单据为准）并交回房屋后，出租方凭承租方提供的材料应在承租方办结退房手续后一个月内将租赁保证金全额（不计息）退还承租方。
            </div>
            <div class="text-indent padding_5_0">
              （四）退房时租赁期满或其他原因导致本合同终止，如承租方违约，出租方有权先从租金等余额中扣除相应金额抵补欠费，仍不足以结清欠费的，出租方有权从租赁保证金中抵扣；如承租方期满退房但仍有未结清费用的，出租方有权先从租赁保证金中扣除相应费用后，再将剩余租赁保证金（不计息）退还承租方。
            </div>
            <div class="text-indent padding_5_0">
              （五）出租方因承租方有第十三条第（五）项约定的情形或承租方自身原因解除本合同，出租方有权没收全部房屋租赁保证金，作为违约金处理。
            </div>
            <div class="text-indent padding_5_0">
              （六）租赁期间，如遇政府调整保障性租赁住房租金标准的，自政府文件规定的调整之日起，出租方有权按新的租金标准与承租方签订补充协议，若租金协商不一致，双方解除租赁合同并退还承租方租赁保证金。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第五条 其他费用承担方式</h3>
            <div class="text-indent padding_5_0">
              （一）租赁期间，燃气费、卫生费、车位费、电视收视费、网络费等费用由承租方承担，按有关单位的收付方式足额交纳；另根据现场物业管理公司规定签订物业管理合同。
            </div>
            <div class="text-indent padding_5_0">
              （二）承租方未按时缴纳上述费用致使出租方或者后续承租方无法正常使用房屋的，承租方应赔偿出租方因此遭受的经济损失，此条款不因合同解除而失效。
            </div>
            <div class="text-indent padding_5_0">
              （三）若智能电表与机械电表产生损耗差异，由承租方承担相应损耗费用。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第六条 房屋交付及收回的约定</h3>
            <div class="text-indent padding_5_0">
              （一）出租方交付的房屋主体及相关配套家具、家电等设施设备应符合正常的使用条件，承租方入住时，双方根据《房屋、设备、设施交接清单及损坏赔偿价格表》（附件一）对房屋内的装修及设备进行核对，并签字确认房屋状况符合《房屋、设备、设施交接清单及损坏赔偿价格表》描述。
            </div>
            <div class="text-indent padding_5_0">
              （二）双方经房屋交验，在《房屋、设备、设施交接清单及损坏赔偿价格表》中签字并签订合同后视为交付完成。房屋交付前的管理费、水电费、卫生费等相关费用由出租方承担。
            </div>
            <div class="text-indent padding_5_0">
              （三）租赁期满承租方不续租或合同解除后，出租方有权收回房屋，承租方应当在合同解除当天内搬离承租房屋。承租方应当结清其应承担(包括水费、电费、燃气费、卫生费、物业管理费、车位费、电视收视费、网络费、公摊水电费、垃圾费等)的费用。
            </div>
            <div class="text-indent padding_5_0">
              （四）租赁期满承租方不续租或合同解除后，出租方有权收回房屋，承租方应当在合同解除当天内搬离承租房屋。
            </div>
            <div class="text-indent padding_5_0">
              承租方应当按照正常使用后的状态交还房屋及其附属物品、设施设备。双方当事人应当对房屋和附属物品、设施设备等使用情况进行交验，承租方应当结清其应承担（包括租金、服务费、室内电费、公摊电费、燃气费、卫生费、车位费、电视收视费、网络费等）的费用，双方当事人在《房屋、设备、设施交接清单及损坏赔偿价格表》中签字或盖章。
            </div>
            <div class="text-indent padding_5_0">
              若承租方交还房屋及其附属物品、设施设备时出现损坏、缺失或无法正常使用的，出租方有权按本合同《房屋、设备、设施交接清单及损坏赔偿价格表》的标准要求承租方赔偿，未列在表内的事项按照实际维修费的标准或按市场价格由承租方负责赔偿。
            </div>
            <div class="text-indent padding_5_0">
              （五）承租方未按照本合同第六条第（三）条款要求向出租方返还房屋并办理退房手续的，逾期不交还房屋的，每逾期一日按第三条约定月租金的10%向出租方支付房屋占用费。
            </div>
          <div class="text-indent padding_5_0">
            同时，承租方交回房屋时，不得留存物品，对未经出租方同意留存的物品，屋内遗留物品视为承租方放弃所有权，出租方有权自行处置，且出租方有权采取包括但不限于断水断电、锁门，或自行收回并进入房屋，造成的相关搬运、保洁、处置等费用，由承租方承担。
          </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第七条 租赁期内房屋使用及维护</h3>
            <div class="text-indent padding_5_0">
              （一）出租方应当确保出租房屋符合安全、防灾等工程建设强制性标准和要求，具备基本供水、供电等生活条件，室内装修符合国家有关标准。
            </div>
            <div class="text-indent padding_5_0">
              （二）承租方应当按照合同约定的租赁用途和使用要求合理使用房屋以及其附属物品、设备设施。不得擅自改动房屋承重结构和拆改室内设施设备，不得擅自更改水电线路管道，不得增加外墙荷载，不得超载使用，不得堆放易燃易爆及危险物品，不得损害公共利益和相邻关系人的合法权益。
            </div>
            <div class="text-indent padding_5_0">
              （三）租赁期间
            </div>
            <div class="text-indent padding_5_0">
              1.房屋及附属物品、智能门锁、智能电表、家电家具等设施设备，承租方不能擅自拆除、更换、搬离，如因承租方原因导致设施设备出现损坏、无法正常使用或丢失，由承租方在租赁期间内承担相关费用，承租方拒不承担的，出租方按违约办理退房，不予退回租赁保证金并按设备原价赔偿出租方损失。
            </div>
            <div class="text-indent padding_5_0">
              2.承租方造成公共区域范围内的配套设备设施、建筑墙体、地板等损坏或缺失的，经核实确实属于承租方责任的，由承租方进行修复并承担相应费用。如承租方未进行修复的，出租方可代为维修，维修费用由承租方承担，如损坏的附属物品、设备设施无法修复的，由承租方按《房屋、设备、设施交接清单及损坏赔偿价格表》进行赔偿，未列在表内事项按照实际维修费计算或按市场价格进行赔偿。相关费用将在承租方账单中以“维修费”方式体现后在五个工作日进行缴交，承租方拒不缴纳超过五个工作日的，出租方有权单方面提前解除本合同，同时按照本合同第四条第（二）项约定执行。
            </div>
            <div class="text-indent padding_5_0">
              （四）未经承租方同意，出租方不得擅自进入租赁住房。有正当理由、确有必要进入租赁住房的，出租方应当与承租方约定时间，承租方应予以配合。
            </div>
            <div class="text-indent padding_5_0">
              （五）若承租方未在本合同规定的时限内向出租方提出对租赁住房续租申请或出租方不同意续租的，则出租方有权在租赁期结束前或提前结束前的三十日内的所有合理时间内，预先通知承租方后视察租赁住房，承租方不得阻挠，但出租方的行为不得干扰承租方对租赁住房的正常使用。
            </div>
            <div class="text-indent padding_5_0">
              在房屋自用部位和自用设施设备中，承租方负责下列项目的维修：
            </div>
            <div class="text-indent padding_5_0">
              1.房屋门窗的玻璃、门框扇等易损易碎物件；
            </div>
            <div class="text-indent padding_5_0">
              2.用户水表后的水管、水龙头、瓷盆、大便器等给、排水设施；
            </div>
            <div class="text-indent padding_5_0">
              3.用户电表后的电线、插座、开关、灯具等供电照明设施；
            </div>
            <div class="text-indent padding_5_0">
              4.承租方自用装修装饰部分、户内墙体表面发霉、涂鸦等污渍，或因粘贴装饰饰面而造成的墙面破损，瓷砖填缝脱落等；
            </div>
            <div class="text-indent padding_5_0">
              5.因使用不当等原因而损坏的房屋智能化设备、房屋构件及配套设施等；
            </div>
            <div class="text-indent padding_5_0">
              6.房屋内已过质保期的热水器、排气扇等家电家具设施设备。
            </div>
            <div class="text-indent padding_5_0">
              7.因承租方使用不当或者人为造成房屋共用部位、共用设施设备损坏的，由其责任人负责修复或者给予赔偿。
            </div>
            <div class="text-indent padding_5_0">
              （六）属于出租方维修的项目，承租方向出租方提出维修请求后，出租方应在合理的时限内提供维修服务。因其他原因暂时不能提供维修的，出租方必须在五个工作日内给予答复。
            </div>
            <div class="text-indent padding_5_0">
              （七）属于承租方负责维修的项目，承租方有权自主选择维修单位，其费用标准及支付方式等具体事项由承租方与维修单位双方自行约定。
            </div>
            <div class="text-indent padding_5_0">
              1.因承租方使用、维修不当发生安全事故造成出租方及第三方人身、财产损失的，承租方承担全部责任。
            </div>
            <div class="text-indent padding_5_0">
              2.出租方发现承租方行为涉及损害房屋结构安全的，出租方有权组织施工力量进行排除险情，承租方必须承担维修排危的费用，同时承租方承担相应责任。
            </div>
            <div class="text-indent padding_5_0">
              （八）租赁期间，出租方需要对房屋进行安全检查或维修的，承租方应按出租方要求给予积极配合，确保出租方能及时完成对出租房屋的常规检查或维修。因承租方原因导致无法按时完成维修，造成出租方及第三方人身、财产损失的，承租方承担全部责任。
            </div>
            <div class="text-indent padding_5_0">
              （九）出租方在检查和维修过程中非因故意或重大过失造成的承租方装饰装修局部破损的，出租方不承担赔偿责任。因承租方原因导致不能及时检查、维修房屋的，承租方承担违约责任，导致房屋安全事故的，承租方承担全部法律责任。
            </div>
            <div class="text-indent padding_5_0">
              （十）租赁期间，出租方和市住房保障部门不定期开展入户调查核对租户信息工作，承租方应予以积极配合。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第八条 装饰装修约定</h3>
            <div class="text-indent padding_5_0">
              （一）出租方的房屋为已完成装饰装修的，承租方不得再进行装饰。
            </div>
            <div class="text-indent padding_5_0">
              （二）承租方确实需要装饰装修的，应填写《保障房装饰装修申报登记表》并提交装修方案、设计单位出具的设计文件、房屋安全鉴定机构出具的可行性方案、规划部门的审批文件、建设部门的审批文件、施工单位营业执照和资质证书复印件（加盖企业公章）等与本次装修有关的材料。待出租方审核批准，承租方交纳相关费用后，方可进行装饰装修。
            </div>
            <div class="text-indent padding_5_0">
              1.承租方未经出租方批准擅自进行装饰装修活动的﹐应向出租方支付违约金2000元，并按出租方要求恢复原状，出租方有权解除租赁合同，收回房屋。
            </div>
            <div class="text-indent padding_5_0">
              2.承租方的装饰装修项目超出经出租方批准的实施内容的，应向出租方支付违约金1000元，并按出租方要求恢复超出部分的原状，出租方有权解除租赁合同，收回房屋。
            </div>
            <div class="text-indent padding_5_0">
              （三）承租方对承租房屋装饰装修时，禁止下列行为：
            </div>
            <div class="text-indent padding_5_0">
              1.拆除或部分拆除具有承重作用的房屋基础、墙体、柱、梁、楼板等构件；
            </div>
            <div class="text-indent padding_5_0">
              2.在承重墙上增设门窗或者扩大承重墙原有的门窗洞口尺寸；
            </div>
            <div class="text-indent padding_5_0">
              3.在悬挑阳台挑梁部位的墙体或者悬挑楼梯的承重墙上开凿洞口；
            </div>
            <div class="text-indent padding_5_0">
              4.在非承重外墙上增设门窗，禁止在建筑外窗设置影响逃生和灭火救援的障碍物；
            </div>
            <div class="text-indent padding_5_0">
              5.改变房屋规划设计用途，变更使用功能、使用易燃、可燃装修装饰材料；
            </div>
            <div class="text-indent padding_5_0">
              6.超过设计标准或者规范增加楼面、屋面、阳台荷载；
            </div>
            <div class="text-indent padding_5_0">
              7.将没有防水功能的房间或者阳台改为卫生间、厨房；
            </div>
            <div class="text-indent padding_5_0">
              8.挖掘房屋地下基础，建设地下设施或者降低房屋室内地坪标高；
            </div>
            <div class="text-indent padding_5_0">
              9.拆改房屋共用部位；
            </div>
            <div class="text-indent padding_5_0">
              10.安装影响房屋结构安全的设施和设备；
            </div>
            <div class="text-indent padding_5_0">
              11.拆改房屋中具有房屋抗震、防火整体功能的非承重结构；
            </div>
            <div class="text-indent padding_5_0">
              12.法律法规、政策规定的其他危及房屋使用安全的行为。本合同所称建筑主体，是指建筑实体的结构构造，包括屋盖、楼盖、梁、柱、支撑、墙体、连接接点和基础等。
            </div>
            <div class="text-indent padding_5_0">
              本合同所称承重结构，是指直接将本身自重与各种外加作用力系统地传递给基础地基的主要结构构件和其连接接点，包括承重墙体、立杆、柱、框架柱、支墩、楼板、梁、屋架、悬索等。
            </div>
            <div class="text-indent padding_5_0">
              承租方有违反本条约定情形之一的，应向出租方支付违约金4000元，并按出租方要求恢复原状，恢复的费用全部由承租方承担（包括但不限于检测鉴定费等），出租方有权解除租赁合同，收回房屋。
            </div>
            <div class="text-indent padding_5_0">
              （四）出租方需安装空调外机、防盗网、雨棚等物件的，其规格、样式、颜色等必须按物业服务单位统一管理标准进行安装。
            </div>
            <div class="text-indent padding_5_0">
              （五）出租方在进行装饰装修时，应充分考虑租赁期限以及已形成附合的装饰装修物无法收回残值等因素，做好装饰装修的必要成本和标准的核算。
            </div>
            <div class="text-indent padding_5_0">
              未经出租方同意，承租方擅自装饰装修，不论租赁合同因何种原因终止或提前解除，承租方已形成附合的装饰装修物归出租方所有，出租方不给予承租方任何补偿。
            </div>
            <div class="text-indent padding_5_0">
              （六）未形成附合的装饰装修物（例如在租赁房屋安装空调机、电视等未与房屋完全结合在一起，可以与房屋分离）的处理约定：
            </div>
            <div class="text-indent padding_5_0">
              不论租赁合同因何种原因终止或提前解除，承租方对未形成附合的装饰装修物，均可拆除取回，但因拆除造成房屋及设施损坏的，承租方应当恢复原状或赔偿出租方损失。
            </div>
            <div class="text-indent padding_5_0">
              （七）承租方经出租方书面同意装饰装修的，已形成附合的装饰装修物（例如在租赁房屋铺设地板砖、吊设天花板、墙壁粉刷油漆、门窗、敷设的水电管道线路等）的处理约定：
            </div>
            <div class="text-indent padding_5_0">
              1.租赁期届满合同终止，承租方在交回房屋时，已形成附合的装饰装修物归出租方所有，承租方不得拆除，出租方不给予承租方任何补偿；
            </div>
            <div class="text-indent padding_5_0">
              2.出租方违约或出租方不打算继续租赁导致合同提前解除的，已形成附合的装饰装修物归出租方所有，承租方不得拆除，出租方不给予承租方任何补偿。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第九条 物业管理约定</h3>
            <div class="text-indent padding_5_0">
              （一）承租方承租的房屋位于物业管理区域内的，承租方应遵守小区物业管理有关规定及物业服务合同各项约定，服从物业服务单位的统一管理，按时缴纳相关费用。因承租方违反小区物业管理有关规定或不履行物业服务合同约定导致出租方权利受损的，出租方可依法向承租方追偿损失，同时，出租方有权解除租赁合同，收回房屋。
            </div>
            <div class="text-indent padding_5_0">
              （二）承租方应自觉接受社区居委会、小区物业服务中心或出租方的环境卫生检查，保持所承租房屋周围的环境整洁，发现其他单位或者个人在所承租房屋的周围张贴小广告、乱扔乱倒废弃物、乱贴乱挂、乱设摊点、私搭乱建、乱停车辆等不文明行为的，可予以劝阻、制止。
            </div>
            <div class="text-indent padding_5_0">
              （三）承租方不履行门前环境卫生责任的，出租方可责令承租方限期整改，承租方逾期不整改的，出租方可按照有关规定追究承租方责任。
            </div>
            <div class="text-indent padding_5_0">
              （四）出租方承担该房屋共用部位、共用设施设备的维修和更新、改造应分摊的费用。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第十条 承租方的义务</h3>
            <div class="text-indent padding_5_0">
              （一）承租方不得在房屋及公共区域吸烟、酗酒、打架、斗殴、侮辱他人，或从事其他违法犯罪活动，如有发生，出租方有权解除合同、没收承租方全部租赁保证金并保留追究当事人责任的权利；因此产生的一切法律责任和后果由承租方自负，与出租方无关；承租方还需赔偿因此造成出租方的损失。
            </div>
            <div class="text-indent padding_5_0">
              （二）承租方必须遵守物业公司制定的物业管理及服务规定，若因承租方违反物业管理有关规定或不履行物业服务合同约定导致出租方权利受损的，出租方可依法向承租方追偿损失，同时，出租方有权解除租赁合同。
            </div>
            <div class="text-indent padding_5_0">
              （三）承租方存在拖欠租金、服务费、室内电费、公摊电费、转租转借所承租的房屋，不按约定期限腾退所承租的房屋或其他违反本合同约定的情形的，出租方可通过智能门锁、智能水电表等限制承租方及其家庭成员进入所承租的房屋。
            </div>
            <div class="text-indent padding_5_0">
              （四）承租方应配合出租方对承租方承租的房屋所在小区、楼栋以及承租的房屋进行升级、改造（如：信息采集录入、小区出入口智能门禁、楼栋单元智能门禁、房屋入户智能门锁安装等），承租方拒不配合的，出租方有权解除租赁合同。
            </div>
          <div class="text-indent padding_5_0">
            （五）不得养宠物，否则视为承租方违约，出租方有权解除合同，并扣除租赁保证金全额。若因承租方私自养宠物导致房屋内家电家具等设施损坏的，出租方有权从租赁保证金扣除相应费用，若租赁保证金无法覆盖出租方损失的，承租方应补足剩余损失。承租方拒不赔偿的，出租方有权追讨损失费用。
          </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第十一条 其他特殊情况</h3>
            <div class="text-indent padding_5_0">
              租赁期间，因城市规划、建设的需要而发生房屋征收时，以发布的征收公告为依据，出租方收到正式开展征收通知时，应及时通知承租方，承租方应做好搬迁准备，搬迁时出租方将给承租方 7 天的搬迁免租期。具体搬迁期限以出租方通知为准，承租方应在出租方通知要求的期限内搬迁完毕，逾期不搬的，属承租方违约，搬迁免租期以及逾期期间按照本合同第六条第（四）项约定收取房屋占用费，同时出租方有权扣除承租方的租赁保证金作为违约金，且造成安全事故或出租方损失的，承租方承担全部责任，包括出租方主张权利提出诉讼而产生诉讼费、律师费、鉴定费等全部费用。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第十二条 合同解除或终止</h3>
            <div class="text-indent padding_5_0">
              （一）因不可抗力致使合同无法继续履行的，出租方应当提前通知承租方，本合同自通知到达承租方时解除。
            </div>
            <div class="text-indent padding_5_0">
              （二）因一方当事人故意隐瞒与订立合同有关的重要事实或者提供虚假情况的，另一方当事人有权解除合同。
            </div>
            <div class="text-indent padding_5_0">
              （三）出租方有下列情形之一的，承租方有权单方解除合同：
            </div>
<!--            <div class="text-indent padding_5_0">-->
<!--                （四）承租方有下列情形之一的，出租方有权单方解除本合同，收回房屋，承租方承担违约责任：-->
<!--            </div>-->
            <div class="text-indent padding_5_0">
              1.出租房屋不符合安全、防灾等工程建设强制性标准的；
            </div>
            <div class="text-indent padding_5_0">
              2.不承担约定的维修义务，致使承租方无法正常使用房屋的；
            </div>
            <div class="text-indent padding_5_0">
              3.因出租方权属或债务纠纷严重影响承租方居住的。
            </div>
            <div class="text-indent padding_5_0">
              （四）承租方有下列情形之一的，出租方有权单方解除本合同，收回房屋，承租方承担违约责任：
            </div>
            <div class="text-indent padding_5_0">
              1.不按照约定支付租金、服务费、室内电费、公摊电费等费用的；
            </div>
            <div class="text-indent padding_5_0">
              2.违反本合同约定，擅自将房屋转租、分租或者出借给他人的；
            </div>
          <div class="text-indent padding_5_0">
            3.擅自改变房屋用途、结构或者实施其他违法建设、危害房屋安全的行为的；
          </div>
          <div class="text-indent padding_5_0">
            4.利用租赁房屋存放危险物品或从事违法犯罪活动的；
          </div>
          <div class="text-indent padding_5_0">
            5.承租方应按政府有关规定按时办理保障性租赁住房保障资格手续，确保入住保障性租赁住房期间保障资格有效。如因家庭人口、购买或其他方式拥有他处住房等不再具备保障性租赁住房保障资格或因其他情形被取消保障资格的，承租方应主动告知出租方及市住房保障部门，并按政策办理相关退房手续；
          </div>

            <div class="text-indent padding_5_0">
              6.申请续租成功后未在原合同有效期办理签订合同的；
            </div>
          <div class="text-indent padding_5_0">
            7.因扰民等原因干扰他人正常生活，导致周边邻居、居委会书面投诉或派出所出警协调；
          </div>
          <div class="text-indent padding_5_0">
            8.饲养宠物的；
          </div>
          <div class="text-indent padding_5_0">
            9.违反合同约定。
          </div>
          <div class="text-indent padding_5_0">
            （五）其他法定的合同解除情形。
          </div>

            <h3 class="padding_5_0 margin_0 text_ind_17">第十三条 违约责任</h3>
            <div class="text-indent padding_5_0">
              （一）承租方迟延交纳租金或其他费用的，出租方需主动通知承租方，通知后承租方仍未按时缴纳相关费用的，出租方有权采取停水停电或锁门等措施，因此造成承租方的损失，承租方自行承担。
            </div>
            <div class="text-indent padding_5_0">
              除本合同第十条、第十一条约定情形外，租赁期内出租方需提前收回房屋的，或承租方需提前退租的，应至少提前一个月通知出租方，出租方应退还承租方已交纳但尚未发生的租金、服务费、室内电费、公摊电费。
            </div>
            <div class="text-indent padding_5_0">
              （二）租赁期限届满或提前解除合同之日起，承租方未按时交还房屋的，应按第六条第（四）条款标准向出租方支付房屋占用费。
            </div>
            <div class="text-indent padding_5_0">
              （三）承租方擅自将房屋转租给第三人、改变房屋用途、拆改变动损坏房屋主体结构，拒绝配合安全检查维修工作以及拒绝不处理承租方责任范围内维修造成出租人损失的，或利用房屋从事违法活动、存放危险物品、损害公共利益的，出租方有权解除合同并要求承租方按照当月租金标准的三倍支付违约金，造成出租方房屋损坏的，承租方还应承担赔偿责任。
            </div>
            <div class="text-indent padding_5_0">
              （四）出租方收取承租方的租金或让承租方继续租赁房屋，不得被视作出租方放弃其向承租方追究承租方违反、不遵守、不履行本合同承租方须遵守及履行的任何协议、条文、规定及条件的权利。
            </div>
            <div class="text-indent padding_5_0">
              （五）承租方有违反本合同约定行为的，包括但不限于违规转租转借，违规装饰装修，拖欠租金、服务费、室内电费、公摊电费、擅自更改水电线路管道等，不按要求腾退房屋，改变房屋用途，拆改房屋结构等，出租方应报市住房保障部门列入承租方不良信用记录。
            </div>
            <div class="text-indent padding_5_0">
              （六）该房屋以房屋现状出租，承租方签订本合同即视为对房屋现状充分知悉，完全了解与认可房源的实际状况及相关约定，不得以不了解房源实际状况为由退租，否则视为承租方违约，出租方有权终止本次交易，并扣除租赁保证金全额。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第十四条 租赁期房屋安全约定</h3>
            <div class="text-indent padding_5_0">
              在租赁期间，承租方是该房屋使用安全的第一责任人，承租方的人身和财产安全均由承租方承担，与出租方无关，包括但不限制于高空抛物、水电、燃气等使用不当，在屋内摔倒等造成的人身伤害，出租方不承担任何责任。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">第十五条 消防安全责任的约定</h3>
            <div class="text-indent padding_5_0">
              （一）根据我国《民法典》《消防法》《广西壮族自治区实施{{zgrm}}办法》等法律法规的规定以及“谁使用谁负责”的原则，承租方作为房屋及附属设施设备的使用人，是房屋及附属设施设备消防安全的责任人，对房屋及附属设施设备的消防安全承担全部管理责任。
            </div>
            <div class="text-indent padding_5_0">
              （二）租赁期间，承租方应按以下约定规范用电，并对房屋所安装、使用的全部电气线路、设施设备承担全部安全责任：
            </div>
          <div class="text-indent padding_5_0">
            1.采用符合设计要求及国家或行业标准的电线、开关、插座、照明器具等电气设备材料和产品，并确保各类设备材料和产品的质量合格，满足用电负荷要求。
          </div>
          <div class="text-indent padding_5_0">
            2.进行电气线路敷设及设备安装的，应严格按照《民用建筑电气设计规范》（JGJ/T16-2008）的规定委托有资质的施工单位实施并且使用具有相关资质单位签章的电气施工图，使用符合国家标准或行业标准的电线、插座、开关等电气设备、材料，电线应套入线槽或线管，安装防短路、过负荷的电路保护开关装置，每组回路应安装符合规范的自动空气开关和漏电开关。
          </div>
          <div class="text-indent padding_5_0">
            3.应规范用电，不得有下列危及用电安全的行为：
          </div>
          <div class="text-indent padding_5_0">
            （1）私自拉接电线或使用不合格的电线、插座、开关等设备或超负荷用电；
          </div>
          <div class="text-indent padding_5_0">
            （2）使用质量不合格或不满足负荷要求的移动电线及排插；
          </div>
          <div class="text-indent padding_5_0">
            （3）使用质量不合格或存在消防安全隐患的电器产品；
          </div>
          <div class="text-indent padding_5_0">
            （4）从事其他危险用电行为。
          </div>
          <div class="text-indent padding_5_0">
            （三）租赁期间，承租方应按下列约定规范用火，并对其用火安全承担全部责任：
          </div>
          <div class="text-indent padding_5_0">
            1.不得在房屋内或公共走道等非厨房部位生火煮食；
          </div>
          <div class="text-indent padding_5_0">
            2.不得在房屋内外摆设产生明火的神台香炉等；
          </div>
          <div class="text-indent padding_5_0">
            3.不得在房屋内或公共部位存放易燃、易爆或有毒物品；
          </div>
          <div class="text-indent padding_5_0">
            4.使用合格的炉灶、排烟设备，并按使用说明书定时清洗，确保清洁、无油烟堆积；
          </div>
          <div class="text-indent padding_5_0">
            5.安全使用燃气、煤气等；
          </div>
          <div class="text-indent padding_5_0">
            6.不得有其他危险用火行为。
          </div>
          <div class="text-indent padding_5_0">
            （四）租赁期间，承租方应保持房屋内部及周边消防（公共）通道和出入口的畅通，不得有下列堵塞消防（公共）通道和出入口的行为：
          </div>
          <div class="text-indent padding_5_0">
            1.占用公共走道、消防通道等乱堆杂物或改为其他用途；
          </div>
          <div class="text-indent padding_5_0">
            2.在公共通道、楼梯间等处安装向内开启的各类门；
          </div>
          <div class="text-indent padding_5_0">
            3.其他堵塞消防通道的行为。
          </div>
          <div class="text-indent padding_5_0">
            （五）承租方应加强对家庭内有精神病患者、智力残障者、阿尔茨海默病者、未成年人等的监管，一是避免其独自留守家中；二是提醒其不要玩火、玩打火机和燃烧物品，家中易燃物品、打火机、通电设备等应妥善保管或放置，避免其轻易获取；三是引导家庭成员改变不良生活习惯，避免安全事故发生。
          </div>
          <div class="text-indent padding_5_0">
            （六）承租方应自觉接受消防部门、派出所、社区居委会、小区物业服务中心和出租方的消防安全检查，并积极主动落实整改。
          </div>
          <div class="text-indent padding_5_0">
            （七）出租方给承租方承租的房屋配置有灭火器材的，承租方负责日常保管及维护，如清洁、检查气压表的压力值等。
          </div>
          <div class="text-indent padding_5_0">
            （八）由于承租方对房屋及附属设施设备的消防安全承担全部管理责任，房屋及附属设施设备发生火灾等消防安全事故的（除人为纵火外），承租方负全部责任。发生火灾时，承租方应立即向消防部门和出租方如实报告，并积极组织人员疏散、抢险救灾，同时主动协助消防部门及出租方开展事故调查。
          </div>
          <div class="text-indent padding_5_0">
            （九）承租方有违反第十五条消防安全责任的约定中第（2）条、第（3）条、第（4）条约定情形之一的，应向出租方支付违约金4000元，同时承租方应在出租方通知要求的期限内进行整改，逾期不整改或整改不符合出租方要求的，出租方有权解除租赁合同，收回房屋。
          </div>
<!--          <div class="text-indent padding_5_0">-->
<!--            （九）承租方有违反第十五条消防安全责任的约定中第（2）条、第（3）条、第（4）条约定情形之一的，应向出租方支付违约金4000元，同时承租方应在出租方通知要求的期限内进行整改，逾期不整改或整改不符合出租方要求的，出租方有权解除租赁合同，收回房屋。-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--暂停-->
<!--          </div>-->
            <h3 class="padding_5_0 margin_0 text_ind_17">第十六条 合租约定</h3>
            <div class="text-indent padding_5_0">
              共同使用区域为客厅、阳台及这些区域里面的相关设施。在合租期间，本着和平、友好相处的原则，合租室友应共同维护居住环境的卫生、安全，爱护屋内设施家具。不得随意打扰对方的私人空间，不得浪费水、电、燃气，不得养宠物。不得占用公共区域，未经同意擅自占用者，出租方有权将物品清除，后果由承租方自行承担，造成的相关搬运、保洁、处置等费用由承租方承担。如造成室友投诉（以房间为单位，被50%及以上的在租房间投诉），核查属实且警告后依然不改正的，出租方有权进行清退并不予以退回租赁保证金。
            </div>
          <h3 class="padding_5_0 margin_0 text_ind_17">第十七条 争议解决方式</h3>
          <div class="text-indent padding_5_0">
            （一）本合同发生的争议，由双方当事人协商；协商不成的，双方有权向房屋所在地人民法院起诉。
          </div>
          <div class="text-indent padding_5_0">
            （二）为实现债权而发生的费用（包括但不限于诉讼费、律师代理费、差旅费、鉴定费、公证费、合理的调查费、保全费及保全保险费等）由违约方承担。
          </div>
          <h3 class="padding_5_0 margin_0 text_ind_17">第十八条 补充协议</h3>
          <div class="text-indent padding_5_0">
            对本合同中未约定或约定不明的内容，双方可根据具体情况签订书面补充协议。补充协议与本合同具有同等法律效力。
          </div>



            <h3 class="padding_5_0 margin_0 text_ind_17">第十九条 合同生效</h3>
            <div class="text-indent padding_5_0">
                本合同自双方签字或盖章之日起生效。本合同的解除应当采用书面形式。
            </div>
            <div style="height: 20px;"></div>
            <div class="flex">
                <div style="text-indent: 2em;width: 40%;">
                  出租方（签章）： 南宁城市建设投资集团有限责任公司<br>
<!--                    <img :src="defaultMenuIcon" style="width: 40%"/>-->
                  <div style="height: 88px;"></div>
                </div>
                <div style="text-indent: 2em;width: 40%;">
                  承租方（签章）：<br>
                    <img v-show="grSrc!==null" :src="grSrc" style="width: 40%"/>
                </div>
            </div>
            <div class="flex">
                <div style="text-indent: 2em;width: 40%;">
                    日期：{{ cmData.signDate }}
                </div>
                <div style="text-indent: 2em;width: 40%;">
                    日期：{{ cmData.signDate }}
                </div>
            </div>
            <div>
                <h3 style="padding-left: 30px">附件一 《房屋、 设备、设施交接清单及损坏赔偿价格表》</h3>
                <h1 style="text-align: center;">《房屋、 设备、设施交接清单及损坏赔偿价格表》</h1>
                <p class="text-indent line-height">
                    交接的房屋：{{ cmData.locationAddress }}
                </p>
                <table style="border: 1px solid #000000;border-collapse: collapse;" width="100%">
                    <tbody>
                    <tr style="padding: 0;margin: 0;" align="center">
                        <td style="border: 1px solid #000000;" rowspan="2" width="15%">设备和设施项目</td>
                        <td style="border: 1px solid #000000;" rowspan="2" width="5%">数量</td>
                        <td style="border: 1px solid #000000;" rowspan="2" width="10%">金额（元）</td>
                        <td style="border: 1px solid #000000;" colspan="2" width="10%">状态确认</td>
                        <td style="border: 1px solid #000000;" rowspan="2" width="15%">设备和设施项目</td>
                        <td style="border: 1px solid #000000;" rowspan="2" width="5%">数量</td>
                        <td style="border: 1px solid #000000;" rowspan="2" width="10%">金额（元）</td>
                        <td style="border: 1px solid #000000;" colspan="2" width="10%">状态确认</td>
                    </tr>
                    <tr style="padding: 0;margin: 0;" align="center">
                        <td style="border: 1px solid #000000;" width="5%">入住</td>
                        <td style="border: 1px solid #000000;" width="5%">退租</td>
                        <td style="border: 1px solid #000000;" width="5%">入住</td>
                        <td style="border: 1px solid #000000;" width="5%">退租</td>
                    </tr>
                    <tr v-for="(item, index) in 13" :key="item + '0' + index" align="center">
                        <td style="border: 1px solid #000000;">
                            {{ cmData.leftList[index] ? cmData.leftList[index].assetsTypeName : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.leftList[index] ? cmData.leftList[index].useCount : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.leftList[index] ? cmData.leftList[index].amount : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.leftList[index] ? "确认" : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;"></td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.rightList[index] ? cmData.rightList[index].assetsTypeName : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.rightList[index] ? cmData.rightList[index].useCount : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.rightList[index] ? cmData.rightList[index].amount : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;">
                            {{ cmData.rightList[index] ? "确认" : "&nbsp;" }}
                        </td>
                        <td style="border: 1px solid #000000;"></td>
                    </tr>
                    <tr align="center">
                        <td style="border: 1px solid #000000;" height="50px">退租赔偿金</td>
                        <td style="border: 1px solid #000000;" colspan="2"></td>
                        <td style="border: 1px solid #000000;">赔偿原因</td>
                        <td style="border: 1px solid #000000;" colspan="6"></td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #000000;" colspan="10">
                            <p>注意事项：</p>
                            <p>1.陪同验房手把手五件事：检查门锁、水龙头、冲马桶（蹲厕）、开热水器、试空调冷暖；</p>
                            <p>2.租户请勿随意移动设备以防光纤损坏，若因承租方原因导致损坏，相关维修费用由承租方自行承担；</p>
                            <p>
                              3.房屋内设施设备为出租方财产，严禁私自搬离；请爱护墙面、家具家电、地板等装修布置，若因承租方原因导致损坏（如在墙面挂贴物品导致漆面受损、桌面或柜体长时浸水受损、烟头烫坏地胶等），相关维修费用由承租方按上表标准或施工方报价自行承担；</p>
                            <p>4.退房前请恢复房间的卫生，垃圾清理完毕，包括油烟、厕所、污迹清除干净，否则退房时按100-500 元不等收取清洁费用；</p>
                            <p>
                              5.租赁合同期限届满后，双方续签租赁合同的，无需再签署本房屋交付确认书，视为出租方已完成房屋交付确认；续签合同期限届满后不再续租的，在办理退房时，承租方应将房间配套的设施设备及卫生状态恢复至交房时的状态交还给出租方。</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #000000;" align="center" rowspan="3">入住确认</td>
                        <td style="border: 1px solid #000000;" colspan="4">出租方：南宁城市建设投资集团有限责任公司</td>
                        <td style="border: 1px solid #000000;" align="center" rowspan="3">退租确认</td>
                        <td style="border: 1px solid #000000;" colspan="4">出租方：</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #000000;height: 70px" colspan="4">
                            房间责任人<br/>（承租方）：
                            <img v-show="grSrc!==null" :src="grSrc" style="width: 40%"/>
                        </td>
                        <td style="border: 1px solid #000000;" colspan="4">房间责任人<br/>（承租方）：</td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #000000;" colspan="4">入住时间：{{ cmData.signDate }}</td>
                        <td style="border: 1px solid #000000;" colspan="4">退租时间：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日</td>
                    </tr>
                    </tbody>
                </table>
            </div>
          <!--          交接单-->
          <div>
            <h3 style="padding-left: 30px">附件二  </h3>
            <h1 style="text-align: center;">房屋交割单</h1>
            <div style="display: flex;justify-content: space-between">
              <div style="width: 498px;height: 295px">厨房<img style="width: 100%;height: 100%" :src="cmData.kitchenImg" alt=""></div>
              <div style="width: 498px;height: 295px">餐厅<img style="width: 100%;height: 100%" :src="cmData.drawingRoomImg" alt=""></div>

              <div style="width: 498px;height: 295px">客厅<img style="width: 100%;height: 100%" :src="cmData.restaurantImg" alt=""></div>
            </div>
            <div style="height: 150px;"></div>
            <div>卧室</div>
            <div style="display: flex;justify-content: space-between">

              <div style="width: 498px;height: 295px" v-for="item in cmData.nuoBedroomImg" :key="item">
                <img style="width: 100%;height: 100%" :src="item" alt="">
              </div>
              <!--              <div style="width: 498px;height: 295px">-->
              <!--                <img style="width: 100%" src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1oI0gp.img?w=768&h=478&m=6&x=428&y=117&s=260&d=260" alt="">-->
              <!--              </div>-->
              <!--              <div style="width: 498px;height: 295px">-->
              <!--                <img style="width: 100%" src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1oI0gp.img?w=768&h=478&m=6&x=428&y=117&s=260&d=260" alt="">-->
              <!--              </div>-->
            </div>
            <div style="height: 150px;"></div>
            <div>其他</div>
            <div style="display: flex;justify-content: space-between">

              <div style="width: 408px;height: 295px" v-for="item in cmData.nuoOtherDeliveryNoteImg" :key="item">
                <img style="width: 100%;height: 100%" :src="item" alt="">
              </div>
              <!--              <div style="width: 408px;height: 295px">-->
              <!--                <img style="width: 100%" src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1oI0gp.img?w=768&h=478&m=6&x=428&y=117&s=260&d=260" alt="">-->
              <!--              </div>-->
              <!--              <div style="width: 408px;height: 295px">-->
              <!--                <img style="width: 100%" src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1oI0gp.img?w=768&h=478&m=6&x=428&y=117&s=260&d=260" alt="">-->
              <!--              </div>-->
              <!--              <div style="width: 408px;height: 295px">-->
              <!--                <img style="width: 100%" src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1oI0gp.img?w=768&h=478&m=6&x=428&y=117&s=260&d=260" alt="">-->
              <!--              </div>-->
            </div>
          </div>
          <div style="height: 99px;"></div>
          <!--          交接单-->
            <h1 style="text-align: center;">房屋使用安全知识</h1>
            <h3 class="padding_5_0 margin_0 text_ind_17">一、房屋使用过程中，不得擅自从事下列行为：</h3>
            <div class="text-indent padding_5_0">
                （一）拆除或者部分拆除具有承重作用的房屋基础、墙体、柱、梁、楼板等构件；
            </div>
            <div class="text-indent padding_5_0">
                （二）在承重墙上增设门窗或者扩大承重墙原有的门窗洞口尺寸；
            </div>
            <div class="text-indent padding_5_0">
                （三）在悬挑阳台挑梁部位的墙体或者悬挑楼梯的承重墙上开凿洞口；
            </div>
            <div class="text-indent padding_5_0">
                （四）在非承重外墙上增设门窗，禁止在建筑外窗设置影响逃生和灭火救援的障碍物；
            </div>
            <div class="text-indent padding_5_0">
                （五）改变房屋规划设计用途，变更使用功能、使用易燃、可燃装修装饰材料；
            </div>
            <div class="text-indent padding_5_0">
                （六）超过设计标准或者规范增加楼面、屋面、阳台荷载；
            </div>
            <div class="text-indent padding_5_0">
                （七）将没有防水功能的房间或者阳台改为卫生间、厨房；
            </div>
            <div class="text-indent padding_5_0">
                （八）挖掘房屋地下基础，建设地下设施或者降低房屋室内地坪标高；
            </div>
            <div class="text-indent padding_5_0">
                （九）拆改房屋共用部位；
            </div>
            <div class="text-indent padding_5_0">
                （十）安装影响房屋结构安全的设施和设备。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">二、为避免高空坠物伤及自身及他人，应做到以下几点：</h3>
            <div class="text-indent padding_5_0">
                （一）养成良好的生活习惯，从自身做起，更要制止他人高空抛物；
            </div>
            <div class="text-indent padding_5_0">
                （二）看到高空有摇摇欲坠的物体时，要及时向物业、消防等相关部门反映，立即消除安全隐患；
            </div>
            <div class="text-indent padding_5_0">
                （三）定期检查自家门窗边沿螺丝和窗框是否出现松动脱落，外墙玻璃是否变形、破裂或发生松动，阳台、天面等悬挂物是否松动，阳台植物、花盆是否有坠落危险等，如有松动应及时加固；
            </div>
            <div class="text-indent padding_5_0">
                （四）及时清理阳台、窗台上易被吹掉的物品，如花盆、晾衣架等；
            </div>
            <div class="text-indent padding_5_0">
                （五）大风暴雨天气，是坠物的高峰期，更要小心观察，且外出时禁止紧贴摆有杂物及有悬挂物的居民楼以及在广告牌下行走或逗留，尽量走有防护的内街，以免发生意外；
            </div>
            <div class="text-indent padding_5_0">
                （六）在进行阳台、窗台及其窗户清洁工作时，要做好防护措施，防止物品坠落伤人。
            </div>
            <h3 class="padding_5_0 margin_0 text_ind_17">三、高层住宅楼居民应当自觉遵守下列防火事项：</h3>
            <div class="text-indent padding_5_0">
                （一）遵守住宅小区防火安全公约和管理规约约定的消防安全事项，对专有或租用部分的消防安全负责，对公共部分的消防安全共同负责；
            </div>
            <div class="text-indent padding_5_0">
                （二）遵守电器安全使用规定，不得超负荷用电，严禁安装不合格的保险丝、片；
            </div>
            <div class="text-indent padding_5_0">
                （三）遵守燃气安全使用规定，经常检查灶具，严禁擅自拆、改、装燃气设施和用具；
            </div>
            <div class="text-indent padding_5_0">
                （四）室内不得存放超过0.5公斤的汽油、酒精、香蕉水等易燃易爆物品；
            </div>
            <div class="text-indent padding_5_0">
                （五）不得卧床吸烟，同时，教育儿童禁止玩火；
            </div>
            <div class="text-indent padding_5_0">
                （六）消防设施、器材不得挪作他用，严防损坏、丢失；
            </div>
            <div class="text-indent padding_5_0">
                （七）楼梯、走道和安全出口等部位须保持畅通无阻，不得擅自封闭，不得堆放物品及存放车辆；
            </div>
            <div class="text-indent padding_5_0">
                （八）电动车、电瓶不准进入电梯及上楼充电及存放；
            </div>
            <div class="text-indent padding_5_0">
                （九）禁止占用电缆井、管道井、避难层(间)和避难走道或者堆放杂物，禁止锁闭避难层(间)和避难走道出入口；
            </div>
            <div class="text-indent padding_5_0">
                （十）学习消防常识，掌握简易的灭火及自救方法，发生火灾及时报警，积极扑救。
            </div>
<!--            <div style="text-align: right;">南宁城市建设投资集团有限责任公司宣</div>-->
            <h1 style="text-align: center;">南宁市保障房消防安全使用须知</h1>
            <div class="line-height">
                各位承租户：
            </div>
            <div class="text-indent padding_5_0">
                为确保您和他人的生命财产安全，请在租住保障房的同时必须做到以下几点：
            </div>
            <div class="text-indent padding_5_0">
                一、务必做到安全用电：
            </div>
            <div class="text-indent padding_5_0">
                1.经常检查使用的开关、插座及各种电器等设施有无破损、老化，禁止“带病”超期使用；
            </div>
            <div class="text-indent padding_5_0">
                2.禁止私拉乱接电线、开关、插座等，禁止使用不合格的电线、开关、电器等产品；
            </div>
            <div class="text-indent padding_5_0">
                3.禁止超负荷用电，禁止同时使用多个电功率较大的电器，如电磁炉、微波炉、即热式热水器等。
            </div>
            <div class="text-indent padding_5_0">
                4.禁止在床上对手机、充电宝、充电电筒等充电设备充电，充电时周边禁止堆放可燃物，对已明显老化的充电设备禁止再使用，及时更换。
            </div>
            <div class="text-indent padding_5_0">
                二、务必做到安全用火、用气：
            </div>
            <div class="text-indent padding_5_0">
                1.禁止在保障住房范围内燃放烟花炮竹，燃放烟花炮竹必须在市政府指定的地点燃放，并远离易燃易爆物品；
            </div>
            <div class="text-indent padding_5_0">
                2.禁止在住房及其周边焚烧香烛、祭品，禁止乱丢烟头、香火等火种，禁止在房间或公共通道内生火煮食；
            </div>
            <div class="text-indent padding_5_0">
                3.经常检查燃气罐、连接灶具的软管、燃气灶开关是否有漏气、破损等现象，做到及时修复更换。如使用的厨房燃具、热水器等属于老旧、破损的，应及时更换成新型、安全的设备，同时对燃具、热水器等设备应定期请专业技术人员进行安全状况检查，以避免因设备原因导致发生安全事故；
            </div>
            <div class="text-indent padding_5_0">
                4.使用煤球、燃气热水器、燃气灶等时，要确保通风，防范一氧化碳中毒；
            </div>
            <div class="text-indent padding_5_0">
                5.要教育好未成年人，监护好有精神残疾的家庭成员，避免其玩火和无监护人在场时使用火源等。
            </div>
            <div class="text-indent padding_5_0">
                三、务必做到电动车安全使用、安全充电：
            </div>
            <div class="text-indent padding_5_0">
                1.购买和使用质量合格的电动车、充电器和电池，禁止使用伪劣、翻新的电池、充电器和电动车配件，禁止非法改装电动车及其配件；
            </div>
            <div class="text-indent padding_5_0">
                2.电动车应停放在安全地点，禁止停放在楼梯间、疏散通道、安全出口处，不得占用消防通道；
            </div>
            <div class="text-indent padding_5_0">
                3.禁止私拉电线为电动车充电，充电插座应安装电源自动保护装置，并按照使用说明书的规定进行电动车充电，充电最长时间不得超过6小时，充电时周围禁止堆放可燃物。
            </div>
            <div class="text-indent padding_5_0">
                四、禁止在公共通道、楼梯间等公共部位停放车辆、堆放杂物等，确保消防救援及逃生通道畅通。
            </div>
            <div class="text-indent padding_5_0">
                五、定期清理居住房屋、阳台内杂物，禁止过多堆放杂物，禁止存放易燃易爆物品。
            </div>
            <div class="text-indent padding_5_0">
                六、养成安全的生活习惯：
            </div>
            <div class="text-indent padding_5_0">
                1.禁止在床、沙发等地方吸烟；
            </div>
            <div class="text-indent padding_5_0">
                2.丢弃烟头要做熄灭火源处理；
            </div>
            <div class="text-indent padding_5_0">
                3.禁止过度、过量饮酒，如发生火灾事故，确保神志清醒确保逃生；
            </div>
            <div class="text-indent padding_5_0">
                4.燃气煮食、使用大功率电磁炉、电熨斗、电炉、取暖器等电器设备时，等加热设备时，必须有人员在场看护、控制，使用完毕后，必须关闭气源电源。
            </div>
            <div class="text-indent padding_5_0">
                5.离家外出时，要切断电源、关闭气源。
            </div>
            <div class="text-indent padding_5_0">
                七、房屋发生火灾等突发事件时，要做好人员安全撤离，并报119、110、120请求救援，同时向房管部门报告。
            </div>
<!--            <div style="height: 80px;line-height: 80px;text-align: right;padding-right: 40px;">南宁城市建设投资集团有限责任公司宣</div>-->

<!--          &lt;!&ndash;新增开始&ndash;&gt;-->
<!--          <div class="line-height">-->
<!--            合同编号： <span class="span_decoration">{{ cmData.contractorNum }}</span>-->
<!--          </div>-->
<!--          <h1 style="text-align: center;">安全生产管理协议（资产租赁类）</h1>-->
<!--          <div class="text-indent padding_5_0">出租方：-->
<!--            <span class="span_decoration">南宁城市建设投资集团有限责任公司</span>-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            承租方： <span class="span_decoration">{{ cmData.lesseeName }}、{{ cmData.lesseeIdCard }}、{{ cmData.phone }}</span>-->
<!--          </div>-->
<!--          <div class="line-height">-->
<!--            各位承租户：-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            为保障国家、企业和双方员工生命和财产安全，根据国家、地方、行业等有关法律法规、标准规范及相关规定，按照“管生产必须管安全”和“谁使用、谁管理、谁负责”的原则，经双方协商一致，签订如下安全管理协议：-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第一条</span>   在租赁期间，乙方是案涉租赁物的实际使用人及管理人，负有全面履行租赁物安全管理的义务及责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第二条</span>    经双方共同对甲方所提供场地及所属的设施设备进行验收认可后，在乙方使用期间，由乙方负责自行安排的机械和所有作业人员的安全管理工作及所造成安全责任事故由乙方自行负责；乙方所有自行安排的机械必须是符合国家相关法律规定且证件齐全并配有合格灭火器，并且提前向甲方提供相关机械和驾驶员的有效证件（复印件）备案；自签订本协议之日起，由乙方负责使用范围内的所有安全管理工作，自乙方负责使用范围内的所有安全管理工作之日起至使用期结束甲方完全搬离甲方使用场所止，该期间所发生一切安全事故，均由乙方承担所有责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第三条</span>    乙方应明确告知甲方其安全管理部门和安全负责人，乙方应建立健全本单位的安全生产规章制度，并按规定配备与本单位所从事的生产经营活动相适应的专兼职安全管理人员，并及时告知甲方，乙方的安全管理人员负责其在使用范围内的所有安全管理工作。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第四条</span>   乙方应遵守国家、地方政府及有关部门在安全方面的法律、法规及其他要求和甲方的各项安全规定，否则，由此引发事故的，由乙方承担全部责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第五条</span>   甲方对其提供乙方使用的堆场及相关设备的安全状况、乙方在管理区域内的安全生产、消防管理情况等，有进行安全检查监督的权利；乙方应接受甲方的安全检查监督。甲方有权督促乙方对其使用范围内发现的各类不安全隐患进行整改；可对乙方的违规行为做出口头和或书面指正、下达隐患整改通知书；乙方拒不整改的，甲方有权单方面解除合同，由此产生的一切损失和费用，均由乙方承担。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第六条</span>   乙方在合同期内，严禁从事国家法律、法规、规章标准禁止的行为，及与其正常经营办公无关的活动；乙方不得动用明火、更改或搭接电源线路、水源线路及消防管线等，确有需要时，必须按规定提前申报，经甲方负责安全的部门批准后方可实施，实施全过程要严格遵守国家有关规定，并做好防范措施，否则，由此引发事故的，由乙方承担全部责任,甲方的批准同意并不能免除或减轻乙方依法应承担的安全义务和责任，且不构成甲方对承担安全责任的承诺；乙方不得私自改变、拆卸甲方原有的设施，乙方在使用范围内进行装修、改造时，必须书面报告甲方有关部门，经批准同意后，方可实施, 否则，由此引发事故的由乙方承担全部责任，甲方的批准同意并不能免除或减轻乙方依法应承担的安全义务和责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第七条</span>  未经甲方同意并办理相关安装及验收手续的，乙方不得在使用范围内安装和使用电气设施设备。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第八条</span>    乙方不得使用高功率、高危险性设备，不得使用或存放危险物品，不得使用国家明令淘汰、禁止使用的生产设备和工艺，不得违法改造和使用特种设备，否则，由此造成的一切后果，由乙方承担全部责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第九条</span>    乙方负责对本单位员工及相关人员的安全教育培训；负责开展本单位使用范围内的安全检查，及时消除安全事故隐患；负责为本单位工作人员和外请人员配备符合国家规定的劳动防护用品，负责教育和监督本单位工作人员和外请人员劳动防护用品的正确使用；负责本单位使用范围内安全标示、安全设施设备及器材（如：灭火器）的设置等。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十条</span>  乙方的经营活动不能影响甲方的正常工作，乙方工作人员未经许可，不得进出与其无关的场所，否则，由此造成的人员伤亡及财产损失，由乙方承担全部责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十一条</span>  乙方车辆在租赁范围内行驶、存放时，应严格执行交通安全管理规定和甲方的有关规定，服从甲方门卫人员的管理和指挥，不得堵塞通道，占压消防井（栓）；乙方要按消防有关规定配备与之业务经营相适的足够的消防器材，并保证其合格有效；必须确保消防通道畅通，严禁在道路两侧停放车辆、堆放物品等堵塞、占用消防通道和安全出口的行为，由此造成的一切后果由乙方负全责。-->
<!--          </div>-->


<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十二条</span>   乙方在合同期间，对所经营范围内的场所及消防、电气等设施设备等负有安全使用、管理和维护保养的责任，并应按有关法规、标准，对所使用范围内的场所及设施设备在乙方使用期间的任何损坏，乙方应按规定赔付；对于因乙方违反安全管理规定及本协议约定而引发的事故，或因此造成甲方（包括甲方受到的处罚）和第三方人员伤亡和财产损失，乙方应承担全部责任。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十三条</span>  双方应不定期开会研究安全工作，协调解决安全工作中出现的问题。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十四条</span>  本协议作为《租赁合同 》（合同编号：  <span class="span_decoration">  </span>  ）的重要附件，与双方签订的《租赁合同》具有同等效力，双方均应认真遵守，严格执行。-->
<!--          </div>-->

<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十五条</span>     违约责任-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            <span class="span_decoration">双方进行协商约定</span>-->

<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十六条</span>     因本协议及本协议在履行过程中发生的争议，由各方友好协商解决，协商不成的，任何一方均有权向    <span class="span_decoration" > &#12288; &#12288; &#12288;     </span>    （选其一）的人民法院起诉：-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            （1）甲方工商注册地（2）乙方工商注册地（3）租赁房屋所在地有管辖权-->
<!--          </div>-->
<!--          <div class="text-indent padding_5_0">-->
<!--            <span style="font-weight: bold">第十七条</span>   本协议一式 <span class="span_decoration"> \ </span>份，甲乙双方各执 <span class="span_decoration"> \ </span>份，  本协议经双方法定代表人或委托代理人签字盖章后生效。有效期从 <span class="span_decoration">{{ cmData.contractStartTime }}</span>到 <span class="span_decoration">{{ cmData.contractEndTime }}</span>止。-->
<!--          </div>-->
<!--          <div style="height: 100px"></div>-->
<!--          &lt;!&ndash;          <div style="height: 80px;line-height: 80px;text-align: right;padding-right: 40px;">南宁城市建设投资集团有限责任公司宣</div>&ndash;&gt;-->
<!--          <div >-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              甲方:（盖章） <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              法定代表人： <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              或委托代理人(签字)： <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->

<!--              签订日期： <span class="span_decoration"> {{ cmData.signDate }}  </span>  <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              <img :src="defaultMenuIcon" style="width: 200px; position: relative;top: -150px;left: 50px;"/>-->
<!--              &lt;!&ndash;              <img :src="defaultMenuIcon" style="width: 200px;"/>&ndash;&gt;-->
<!--            </div>-->
<!--            <div style="height: 80px"></div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              乙方:（盖章）<br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              法定代表人： <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->
<!--              或委托代理人(签字)： <br>-->
<!--            </div>-->
<!--            <div style="text-indent: 2em;width: 40%;">-->

<!--              签订日期： <span class="span_decoration"> {{ cmData.signDate }} </span>  <br>-->
<!--            </div>-->

<!--          </div>-->
          <!--       新增结束   -->
            <div class="flex justify-center" style="margin-top: 30px">
                <el-button type="primary" @click="startSigning">开始签约</el-button>
                <el-button type="success" :disabled="cmData.procedureNo === null || formdata === null "
                           @click="submitSignature">
                    提交签名
                </el-button>
            </div>
        </div>
        <div style="height: 500px;">
            <div v-show="isShow" class="flex justify-center">
                <h2>签署区</h2>
            </div>
            <div v-show="isShow" class="flex justify-center">
                <canvas id="canvas" :width="width" :height="height" style="margin: 0 auto;">
                    浏览器不支持canvas
                    <!-- 如果不支持会显示这段文字 -->
                </canvas>
            </div>
            <div v-show="isShow" class="flex justify-center">
                <el-button type="primary" @click="setBackGround">清空</el-button>
                <el-button type="success" @click="createImage">提交</el-button>
            </div>
        </div>

        <r-e-dialog title="签约验证" :visible.sync="yanzhengIsShow" show-footer top="10vh" width="600px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="100px" size="small">
                <div class="flex justify-between">
                    <el-form-item label="手机号码">
                        <el-input type="text" v-model="cmData.phone" :readonly="true" style="width: 250px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="验证码">
                        <el-input type="number" v-model="smsCode" style="width: 250px;" :max="999999" :min="100000"
                                  placeholder="请填写验证码"/>
                    </el-form-item>
                    <el-form-item label="" label-width="20px">
                        <el-button size="mini" :disabled="yzYes" @click="sendSmsCode">
                            {{ yzYes ? yzJiShi + "s后获取" : "获取验证码" }}
                        </el-button>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <el-dialog title="提示" :visible.sync="tipsIsShow" width="30%" :close-on-click-modal="false"
                   :close-on-press-escape="false">
            <span>合同签署已经完成，本页面将在{{ nums }}秒后关闭，点击确定可快速关闭，
                <el-link type="primary" :underline="false" @click="downloadContract">下载合同</el-link>
            </span>
            <span slot="footer">
                <el-button type="primary" @click="closeWindow">确 定</el-button>
            </span>
        </el-dialog>

        <r-e-dialog title="认证" :visible.sync="nuonuoIsShow" show-footer top="10vh" width="600px"
                    @click-submit="nuonuoClickSubmit" @click-cancel="nuonuoClickCancel" @close="nuonuoClickCancel">
            <div class="text-center" style="color: red; width: 100%;margin-bottom: 15px">
                请用手机扫描二维码完成认证
            </div>
            <div class="flex justify-center">
                <div style="width: 300px;height: 300px;border: 1px solid #F1F1F3;border-radius: 5px;">
                    <el-image class="flex align-center" :src="createFullImageUrl(QRCodeUuid)">
                        <div slot="error" style="font-size: 30px;">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="placeholder">加载中...</div>
                    </el-image>
                </div>
            </div>
        </r-e-dialog>
    </div>
</template>
<script>
import {
    createPersonalCharter, sendSmsCode, create, downloadContract, getBzfContractData, expordBzfPdf, getPhoneCheck,
    uploadBzfContract, bzfSignWithCaptcha, bzfSign, personalRegister, getFaceAuthenticationUrl, getNuonuoUser,
    bzfWillingAddress, getSigningStatus
} from "@/api/contract";
import {baseConfig} from "@/utils/config";
import {MessageError, MessageSuccess} from "@custom/message";
import {createFullImageUrl} from "@/components/Upload/index";
import {ParseFloatFormat} from "@/utils/custom";

const defaultMenuIcon = require("@images/BZFZ.png");
export default {
    name: 'test-canvas',
    data() {
        return {
          zgrm:'<中华人民共和国消防法>',
            defaultMenuIcon,
            paramData: {},
            cmData: {
                bathroomType: [],
                furnitureType: [],
                appliancesType: [],
                guardType: [],
                otherType: [],
                otherType1: [],
                leftList: [],
                rightList: [],
            },
            pdfBase64: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            penColor: "#000000",
            penWidth: 4,
            penClick: false,
            startAxisX: 0,
            startAxisY: 0,
            brackGroudColor: "#ffffff",
            progressValue: 0.2,
            showTab: 0,

            isShow: false,
            grSrc: null,
            formdata: null,
            charterId: null,

            yanzhengIsShow: false,
            smsCode: null,

            closeWindowSetInterval: null,
            yzYes: false,
            yzJiShi: 120,

            tipsIsShow: false,
            nums: 30,

            QRCodeUuid: null,
            nuonuoIsShow: false,

        }
    },
    props: {
        height: {
            type: Number,
            default: 376
        },
        width: {
            type: Number,
            default: 800
        },
        defaultPenColor: {
            type: String,
            default: '#000000'
        },
        defaultPenSize: {
            type: Number,
            default: 3
        },
        defaultBackGroundColor: {
            type: String,
            default: "rgba(0, 0, 0, 0)"
        },
        toolsTabList: {
            type: Boolean,
            default: false
        }
    },
    created() {

    },
    mounted  () {

        let that = this;
        let paramData = localStorage.getItem('paramData') || null;
      console.log(paramData)
         debugger
        if (paramData) {
            paramData = JSON.parse(paramData);
            let {uuid} = paramData;
            uuid = uuid || null;
            that.paramData = {...paramData, uuid};
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            // 获取保租房临时合同信息
            getBzfContractData(paramData).then(res => {
                let {info} = res;
              if(info.kitchenImg)  {
                info.kitchenImg =   createFullImageUrl(info.kitchenImg)
              }
              if(info.drawingRoomImg) {
                info.drawingRoomImg =   createFullImageUrl(info.drawingRoomImg)
              }
              if(info.restaurantImg)   {
                info.restaurantImg =   createFullImageUrl(info.restaurantImg)
                // alert(info.restaurantImg)
              }
              if(info.nuoBedroomImg)    {
                info.nuoBedroomImg = info.nuoBedroomImg.split(',').map(item => {
                  return   createFullImageUrl(item)
                })
              }
              if(info.nuoOtherDeliveryNoteImg)  {
                info.nuoOtherDeliveryNoteImg = info.nuoOtherDeliveryNoteImg.split(',').map(item => {
                  return   createFullImageUrl(item)
                })
              }
                let {otherType, rightList, leftList} = info;
                otherType = otherType.filter(item => item.dataValue !== null && item.dataValue !== "0");
                let otherType1 = [];
                let arr = [];
                for (let i = 0; i < otherType.length; i++) {
                    if (i % 2 === 0) arr.push(otherType[i]);
                    else otherType1.push(otherType[i]);
                }
                info.leftList = leftList.map((item) => {
                    const amount = ParseFloatFormat(item.amount);
                    return {...item, amount}
                });
                info.rightList = rightList.map((item) => {
                    const amount = ParseFloatFormat(item.amount);
                    return {...item, amount}
                });
                info.otherType = arr;
                info.otherType1 = otherType1;
                that.cmData = info;
            }).finally(() => loading.close());
        }
        //读取参数后，删除
        localStorage.removeItem('paramData');

        this.init();
    },
    methods: {
      // 开始签约
        async startSigning() {
            let that = this;
            let {lesseeName: name, phone: phone, lesseeIdCard: idcard} = that.cmData;
            phone = phone.trim();
            idcard = idcard.trim();
            if (!name) {
                MessageError('请完善您的姓名');
                return;
            }
            if (!phone) {
                MessageError('请完善您的手机号');
                return;
            }
            if (!idcard) {
                MessageError('请完善您的身份证号');
                return;
            }
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            const info = await that.getNuonuoUser(name, idcard, phone);
            if (info) {
                loading.close();
                const {certified} = info;
                if (certified === 0) {
                     // 获取诺诺人脸认证二维码
                    that.getFaceAuthenticationUrl();
                } else {
                    // 创建诺诺电子合同
                    that.createNuoNuoContact();
                }
            }
        },
        // 创建诺诺电子合同
        async createNuoNuoContact() {
            const that = this;
            let paramData = that.paramData;
            // 查询诺诺电子合同状态
            await that.getSigningStatus(paramData.uuid);

            let cmData = that.cmData;
            const {procedureNo} = cmData;

            if (procedureNo) {
                that.isShow = true;
                return;
            }

            if (paramData.uuid === null) {
                this.$confirm('是否检查完合同, 是否开始签署?', '提示', {type: 'warning'}).then(async () => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let res = await create(paramData);
                    let {info: {uuid}} = res;
                    that.paramData = {...paramData, uuid};
                    // 下载保租房合同模板
                    expordBzfPdf(uuid).then(res => {
                        const blob = new Blob([res], {type: "application/pdf;chartset=UTF-8"});
                        that.blobToBase64(blob, (dataurl) => {
                            let base64 = dataurl.split("data:application/pdf;chartset=utf-8;base64,");
                            that.pdfBase64 = base64[1];
                            // 上传合同文件创建保租房签署流程
                            that.uploadContract();
                        });
                    }).finally(() => loading.close());
                });
            } else {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                expordBzfPdf(paramData.uuid).then(res => {
                    const blob = new Blob([res], {type: "application/pdf;chartset=UTF-8"});
                    that.blobToBase64(blob, (dataurl) => {
                        let base64 = dataurl.split("data:application/pdf;chartset=utf-8;base64,");
                        that.pdfBase64 = base64[1];
                        that.uploadContract();
                    });
                }).finally(() => loading.close());
            }
        },
        // 上传合同文件创建保租房签署流程
        uploadContract() {
            const that = this;
            let paramData = that.paramData;
            let {uuid} = paramData;
            let base64 = that.pdfBase64;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            uploadBzfContract({base64, uuid}).then(res => {
                let {msg} = res;
                that.cmData.procedureNo = msg;
                that.isShow = true;
            }).catch(err => {
                if (err) {
                    const {data: {resultCode = 1001}} = err;
                    if (resultCode === 1009) {
                        setTimeout(() => that.closeWindow(), 3 * 1000);
                    }
                }
            }).finally(() => loading.close());
        },
        // 提交签名
        async submitSignature() {
            let that = this;
            let formdata = that.formdata;
            const {uuid} = that.paramData;
            if (formdata) {
                await that.getSigningStatus(uuid);

                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                // 创建个人模板印章
                createPersonalCharter(formdata).then(res => {
                    let {info} = res;
                    let {charterId} = info;
                    that.charterId = charterId;
                    this.$confirm('请选择签署方式，注：短信验证签署请确保当前手机号是当前身份证号办理, 否则会收不到短信验证码', '提示', {
                        confirmButtonText: '人脸意愿签署',
                        cancelButtonText: '短信验证签署',
                        type: 'warning',
                        center: true,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        showClose: false,
                    }).then(() => {
                        //诺诺获取人脸意愿认证地址
                        that.bzfWillingAddress();
                    }).catch(() => {
                        // 打开短信验证签署弹窗
                        that.yanzhengIsShow = true;

                    });
                }).catch(err => {
                    that.grSrc = null;
                    that.startSigning();
                    that.formdata = null;
                }).finally(() => loading.close());
            } else {
                that.yanzhengIsShow = false;
                MessageError("未签名,无法提交");
            }
        },
        // 点击获取验证码
        async sendSmsCode() {
            let that = this;
            let paramData = that.paramData;
            let {uuid} = paramData;

            await that.getSigningStatus(uuid);

            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            // 获取签署验证码
            sendSmsCode(uuid).then(res => {
                MessageSuccess("短信发送成功");
                that.yzYes = true;
                let jishi = setInterval(() => that.yzJiShi = that.yzJiShi - 1, 1000);
                setTimeout(() => {
                    clearInterval(jishi);
                    that.yzJiShi = 120;
                    that.yzYes = false;
                }, 120 * 1000);
            }).finally(() => loading.close());
        },
        // 签约认证弹窗确认按钮
        async clickSubmit() {
            let that = this;
            let paramData = that.paramData;
            let {uuid} = paramData;

            await that.getSigningStatus(uuid);

            let smsCode = that.smsCode;
            let charterId = that.charterId;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            if (smsCode === null || smsCode === "") {
                MessageError("请输入验证码");
                return;
            }
            // 保租房合同签署（短信意愿认证）
            bzfSignWithCaptcha({uuid, smsCode, charterId}).then(res => {
              // 保租房合同完善(签约完成最后一步)
                bzfSign({uuid}).then(r => {
                    MessageSuccess("签约成功");
                    that.clickCancel();
                    that.tipsIsShow = true;
                    that.closeWindowSetInterval = setInterval(() => that.nums = that.nums - 1, 1000);
                    setTimeout(() => that.closeWindow(), 30 * 1000);
                }).finally(() => loading.close());
            }).finally(() => loading.close());
        },

        clickCancel() {
            this.yzYes = false;
            this.smsCode = null;
        },

        closeWindow() {
            let that = this;
            clearInterval(that.closeWindowSetInterval);
            window.opener = null;
            window.open('', '_self');
            window.close();
        },
        // 下载合同
        downloadContract() {
            let that = this;
            let paramData = that.paramData;
            let {uuid} = paramData;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            // 下载合同
            downloadContract(uuid).then(res => {
                let {msg} = res;
                if (msg) {
                    let blob = that.base64ToBlob(msg);
                    let pdfUrl = window.URL.createObjectURL(blob);
                    let tempwindow = window.open("", '_blank');
                    tempwindow.location = pdfUrl;
                }
            }).finally(() => loading.close());
        },

        //诺诺个人认证查询
        async getNuoNuoAuthentication() {
            let that = this;
            let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber} = that.cmData;
            phone = phone.trim();
            idNumber = idNumber.trim();
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //  添加nuonuo个人用户
            const {info} = await personalRegister({userName, idNumber, phone}).finally(() => loading.close());
            return info;
        },

        // 获取诺诺人脸认证二维码
        getFaceAuthenticationUrl() {
            let that = this;
            let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber} = that.cmData;
            phone = phone.trim();
            idNumber = idNumber.trim();
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            getFaceAuthenticationUrl({userName, idNumber, phone}).then(res => {
                let {msg} = res;
                that.nuonuoIsShow = true;
                that.QRCodeUuid = msg;
            }).finally(() => loading.close());
        },
        //诺诺人脸认证二维码窗体确认
        async nuonuoClickSubmit() {
            let that = this;
            const {certified} = await that.getNuoNuoAuthentication();
            if (certified === 0) {
                MessageError("请先完成认证");
            } else {
                that.nuonuoClickCancel();
                that.createNuoNuoContact();
            }
        },

        //诺诺人脸认证二维码窗体关闭
        nuonuoClickCancel() {
            let that = this;
            that.nuonuoIsShow = false;
            that.code = null;
            that.orderNo = null;
        },

        //诺诺获取人脸意愿认证地址
        bzfWillingAddress() {
            let that = this;
            let {lesseeName: userName, phone: phone, lesseeIdCard: idNumber, procedureNo} = that.cmData;
            phone = phone.trim();
            idNumber = idNumber.trim();
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            bzfWillingAddress({userName, phone, idNumber, procedureNo}).then(res => {
                that.$alert('已通过短息形式，将人脸意愿签署链接发送至当前手机，请租户收到短信后，将短信链接复制到手机浏览器中，进行认证，认证完成后自动签署完成', '提醒', {
                    confirmButtonText: '确定',
                    callback: () => {
                        that.closeWindow();
                    }
                });
            }).finally(() => loading.close());
        },

        //页面初始化
        init() {
            let height = this.height;
            let width = this.width;
            if (width === -1) {
                const cbw = document.getElementById('canvas-broad');
                width = cbw.offsetWidth * 0.9;
                height = cbw.offsetHeight * 0.6;
                this.width = width;
                this.height = height;
            }
            this.penColor = this.defaultPenColor;
            this.brackGroudColor = this.defaultBackGroundColor;
            this.penWidth = this.defaultPenSize;

            let canvas = document.getElementById('canvas'); //获取canvas标签
            let ctx = canvas.getContext("2d");//创建 context 对象
            ctx.fillStyle = this.brackGroudColor;//画布背景色
            ctx.fillRect(0, 0, width, height);//在画布上绘制 width * height 的矩形，从左上角开始 (0,0)
            canvas.addEventListener("mousemove", this.drawing); //鼠标移动事件
            canvas.addEventListener("mousedown", this.penDown); //鼠标按下事件
            canvas.addEventListener("mouseup", this.penUp); //鼠标弹起事件
        },
        // 清空
        setBackGround() {
            const canvas = document.getElementById('canvas'); //获取canvas标签
            const ctx = canvas.getContext("2d");//创建 context 对象
            ctx.clearRect(0, 0, this.width, this.height);//在画布上绘制 600x300 的矩形，从左上角开始 (0,0)
        },
        // 鼠标按下事件
        penDown(event) {
            this.penClick = true;
            this.startAxisX = event.pageX;
            this.startAxisY = event.pageY;
        },
        //鼠标弹起事件
        penUp() {
            this.penClick = false;
        },
        //鼠标移动事件
        drawing(event) {
            if (!this.penClick) return;
            const canvas = document.getElementById('canvas'); //获取canvas标签
            const ctx = canvas.getContext("2d");//创建 contextconst canvas = document.getElementById('canvas');  对象
            const stopAxisX = event.pageX;
            const stopAxisY = event.pageY;
            const left = document.getElementById('leftMenu');
            const lw = left && left.offsetWidth ? (left.offsetWidth || 0) / 2 : 0;
            ctx.beginPath();
            //由于整体设置了水平居中，因此需要做特殊处理：window.screen.availWidth/2 -300
            const wsaW = window.screen.availWidth;
            const cl = canvas.offsetLeft;
            const ct = canvas.offsetTop;
            ctx.moveTo(this.startAxisX - cl, this.startAxisY - ct);//moveTo(x,y) 定义线条开始坐标
            ctx.lineTo(stopAxisX - cl, stopAxisY - ct);//lineTo(x,y) 定义线条结束坐标
            ctx.strokeStyle = this.penColor;
            ctx.lineWidth = this.penWidth;
            ctx.lineCap = "round";
            ctx.stroke();// stroke() 方法来绘制线条
            this.startAxisX = stopAxisX;
            this.startAxisY = stopAxisY;
        },

        createImage() {
            let that = this;
            let cmData = that.cmData;
            let {lesseeIdCard} = cmData;
            const canvas = document.getElementById('canvas'); //获取canvas标签
            const img_png_src = canvas.toDataURL("image/png"); //将画板保存为图片格式的函数
            // console.log('=====', img_png_src);//data:image/png;base64,iVBOR.....
            this.grSrc = img_png_src;
            this.isShow = false;
            const file = this.dataURLtoFile(img_png_src, 'png1');
            const formdata = new FormData();
            // 这里只是基本设置，对应接口需求设置响应的类型属性值
            formdata.set('file', file);
            formdata.set("Idcard", lesseeIdCard);
            this.formdata = formdata;
            that.setBackGround();
        },

        //将base64转换为文件
        dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },

        blobToBase64(blob, callback) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                callback(e.target.result);
            };
            fileReader.readAsDataURL(blob);
        },

        base64ToBlob(code) {
            //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
            code = code.replace(/[\n\r]/g, "");
            let raw = window.atob(code);
            let rawLength = raw.length;
            //转换成pdf.js能直接解析的Uint8Array类型
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: "application/pdf"}); //转成pdf类型
        },

        //图片回显
        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },
        // 查找个人租客
        async getNuonuoUser(userName, idNumber, phone) {
            const data = {userName, idNumber, phone}
            const res = await getNuonuoUser(data);
            if (res) {
                const {info} = res;
                return info;
            } else {
                return null;
            }
        },
        // 查询诺诺电子合同状态
        async getSigningStatus(uuid) {
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            const res = await getSigningStatus({uuid}).finally(() => loading.close());
            if (res) {
                const {info} = res;
                if (info) {
                    const {procedureStatus, procedureNo} = info;
                    if (procedureStatus === 0) {
                        this.cmData.procedureNo = null;
                    }
                    if (procedureStatus === 1) {
                        this.cmData.procedureNo = procedureNo;
                    }
                    if (procedureStatus === 2) {
                        this.$alert('当前电子合同已签署完成，请勿重复签署', '提示', {
                            confirmButtonText: '确定',
                            callback: () => { this.closeWindow(); }
                        });
                    }
                } else {
                    this.$alert('当前流程异常，请稍后重试', '提示', {
                        confirmButtonText: '确定',
                        callback: () => { this.closeWindow(); }
                    });
                }
            } else {
                this.$alert('当前流程异常，请稍后重试', '提示', {
                    confirmButtonText: '确定',
                    callback: () => { this.closeWindow(); }
                });
            }
        }
    },
    watch: {
        brackGroudColor: {
            handler(newVal, oldVal) {
                this.setBackGround();
            }
        }
    },
}
</script>

<style lang="scss" scoped="scoped">

#canvas {
    border: 2px solid #ff6700;
    cursor: crosshair;
    /*不能用这种方式给canvas设置宽高*/
    /*width: 600px;*/
    /*height: 300px;*/
}

.text-indent {
    text-indent: 2em;
}

.padding_5_0 {
    padding: 5px 0;
}

.margin_0 {
    margin: 0;
}

.text_ind_17 {
    text-indent: 1.7em;
}

.span_decoration {
    border-bottom: 1px black solid;
    padding: 0 10px;
}
</style>